import {
  Box,
  Center,
  HStack,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'
import { HiInformationCircle } from 'react-icons/hi'

import ProductVariantStockGraph from './ProductVariantStockGraph'

const ProductVariantDetails = ({ variant, listed }) => {
  const stockGraph = (
    <Box px="4" py="4" bg={useColorModeValue('gray.100', 'gray.900')}>
      {variant.stockHistory.length >= 6 ? (
        <Box minHeight="300px">
          <ProductVariantStockGraph stockHistory={variant.stockHistory} />
        </Box>
      ) : (
        <Center>
          <Text opacity={0.3}>Not enough data for graph.</Text>
        </Center>
      )}
    </Box>
  )
  const showGraph = useBreakpointValue({ base: false, sm: true })

  const lastKnownPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(variant.priceHistory[0].price)

  const maxPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(
    Math.max.apply(
      null,
      variant.priceHistory.map((x) => x.price)
    )
  )
  const minPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(
    Math.min.apply(
      null,
      variant.priceHistory.map((x) => x.price)
    )
  )

  return (
    <SimpleGrid
      templateColumns={{ base: 'auto', sm: 'auto', md: '350px auto' }}
      spacing={0}
      rounded="md"
      shadow="base"
      overflow="hidden"
    >
      <Box px="4" py="4" bg={useColorModeValue('white', 'gray.800')}>
        <Text fontWeight="semibold">{variant.details.title}</Text>
        <Text fontSize="sm" color="gray.400">
          {variant.details.sku}
        </Text>
        <HStack mt={{ base: '4', md: '8' }}>
          <Stat>
            <StatLabel>Last known price</StatLabel>
            <StatNumber>
              <HStack alignItems="flex-start" spacing={0}>
                <Text>{lastKnownPrice}</Text>
                {maxPrice === minPrice ? (
                  <></>
                ) : (
                  <Popover placement="right">
                    <PopoverTrigger>
                      <Text lineHeight={6}>
                        <Icon
                          as={HiInformationCircle}
                          w={4}
                          h={4}
                          cursor="pointer"
                          ml={1}
                        />
                      </Text>
                    </PopoverTrigger>
                    <PopoverContent width="auto">
                      <PopoverArrow />
                      <PopoverBody>
                        <Text fontSize="sm" fontWeight="normal">
                          Highest: {maxPrice}
                        </Text>
                        <Text fontSize="sm" fontWeight="normal">
                          Lowest: {minPrice}
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
              </HStack>
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Current stock</StatLabel>
            <StatNumber>
              {listed ? (
                variant.stockHistory[0].stock
              ) : (
                <Tag size="md" colorScheme="gray" variant="solid" mt={2}>
                  Unlisted
                </Tag>
              )}
            </StatNumber>
          </Stat>
        </HStack>
      </Box>
      {showGraph ? stockGraph : <></>}
    </SimpleGrid>
  )
}

export default ProductVariantDetails

// {maxPrice == minPrice ? (
//   <></>
// ) : (
//   <VStack spacing={0}>
//     <Text fontSize="xs" fontWeight="normal" lineHeight="12px">
//       Highest: {maxPrice}
//     </Text>
//     <Text fontSize="xs" fontWeight="normal" lineHeight="12px">
//       Lowest: {minPrice}
//     </Text>
//   </VStack>
// )}
